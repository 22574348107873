<template>
  <v-container style="background: none!important;">
    <v-toolbar flat dark style="
        background-color: transparent !important;
        text-align: center;
        margin-top: -75px;
      ">
    </v-toolbar>

    <v-card flat class="card-report">
      <v-card-text class="p-0 card-backround">
        <template>
          <v-form>
            <v-container>
              <v-row>
                <v-col sm="12" lg="12" md="12">
                  <template>
                    <HeaderWithdraw @child-checkbox="dateWinLoseFilterValue" />
                    <v-data-table style="font-family: 'khmer mef1'; background-color: rgba(0,0,0,.6);"
                      class="elevation-1 theme--dark data-table" item-key="_id" mobile-breakpoint="NaN"
                      hide-default-header
                      :headers="headersReportWithdraw" :footer-props="footerProps" :page.sync="dataRB.page"
                      :items="dataReportWithdraw.reports" :items-per-page.sync="dataRB.rowsPerPage"
                      :server-items-length="dataReportWithdraw.total_rows" :loading="getLoading"
                      @update:options="reportWithdraw" @update:page="reportWithdraw"
                      @update:items-per-page="reportWithdraw" @update:sort-desc="reportWithdraw">

                      <template v-slot:header="{ props }">
                        <th v-for="(header, index) in props.headers" :key="index" class="text-center pt-3 header-text-style" >
                           {{ $t(`title.${header.text}`)}}
                          </th>
                      </template>
                      <template v-slot:[`item.date`]="{ item }">
                        {{ item.date}}
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        <span v-if="item.amount >= 0" class="c-white">
                          {{ currencyFormat(item.amount) }}
                        </span>
                        <span v-if="item.amount < 0" class="c-white">
                          {{ currencyFormat(item.amount) }}
                        </span>
                      </template>
                      <template v-slot:[`item.old_balance`]="{ item }">
                        {{ currencyFormat(item.old_balance) }}
                      </template>
                      <template v-slot:[`item.new_balance`]="{ item }">
                        {{ currencyFormat(item.new_balance) }}
                      </template>
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import headersReportWithdraw from "./_api/columnsReportWithdraw";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    HeaderWithdraw: () => import("./components/HeaderWithdraw.vue"),
  },
  data() {
    return {
      headersReportWithdraw: headersReportWithdraw,
      footerProps: {
        "items-per-page-options": [10, 20, 50, 100, 200],
      },
      loading: false,
      channelType: 1,
      dialog: false,
      account_info: null,
      dataRB: {
        page: 1,
        rowsPerPage: 10,
      },
      dateFilterRB: {},
    };
  },
  item: {
    type: Object,
    default: null,
  },
  mounted() {
    this.channelType = this.$cookies.get("sbc_channel_type")
      ? this.$cookies.get("sbc_channel_type")
      : 1;
  },
  watch: {
    channelId: function (newVal, oldVal) {
      // console.log(`channelId new: ${newVal} old: ${oldVal}`)
      if (newVal != oldVal) {
        this.channelType = newVal;
        this.reportWithdraw();
      }
    },
  },
  computed: {
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapGetters("$_withdraw", {
      dataReportWithdraw: "getReportWithdraw",
    }),
    ...mapGetters("$_withdraw", ["getLoading"]),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },

  methods: {
    dateWinLoseFilterValue: function (params) {
      this.dateFilterRB = params;
      this.dataRB = {
        page: 1,
        rowsPerPage: 10,
      };
      this.reportWithdraw();
    },
    reset() {
      this.$refs.form.reset();
    },
    getUserCurrency() {
      if (this.userInfor.currency_type === 1) {
        return "$";
      } else if (this.userInfor.currency_type === 2) {
        return "៛";
      } else if (this.userInfor.currency_type === 3) {
        return "฿";
      }
    },
    currencyFormat(num) {
      num = parseFloat(num);
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    async getUserInfo() {
      await this.fetchUserInfo();
    },
    async reportWithdraw() {
      let body = {};
      if (
        this.dateFilterRB.startDate != null &&
        this.dateFilterRB.endDate != null
      ) {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
          start_date: this.dateFilterRB.startDate,
          end_date: this.dateFilterRB.endDate,
        };
      } else {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
        };
      }
      await this.fetchReportWithdraw(body);
    },
    ...mapActions("$_myinfo", ["fetchMyInfo"]),
    ...mapMutations("$_withdraw", ["REPORT_WITHDRAW"]),
    ...mapActions("$_withdraw", ["fetchReportWithdraw"]),
  },
};
</script>

<style scoped>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

.container {
  height: auto !important;
}

.header-text-style{
  font-weight:600; 
  font-size:14px; 
  color: rgba(255, 255, 255, 0.698);
  padding-bottom:15px;
  border-bottom:1px solid rgb(128, 128, 128,.5);
}
</style>